import {
  LOCALISED_COUNTRIES,
  LOCALISED_EU_CONTRIES,
} from 'state/user/constants';
import { floor } from 'lodash';
import {
  DurationType,
  Product,
  Subscription,
  TrialDurationType,
} from 'types/product';
import { handleError } from './error';
import { store } from '../index';

const getCurrency = (currencyId: string) => {
  switch (currencyId) {
    default:
      return '$';
    case 'GBP':
      return '£';
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
  }
};

const getTrialDailyPrice = (price: number, days: number) => price / days;

const getTrialDays = (type: TrialDurationType, duration: number) => {
  if (type === 'days') {
    return duration;
  }
  if (type === 'weeks') {
    return duration * 7;
  }
  if (type === 'months') {
    return duration * 30;
  }

  return 0;
};

const getDailyPrice = (months: number, price: number) => {
  const daysInAMonth = 30;

  return price / (daysInAMonth * months);
};

const getWeeklyPrice = (months: number, price: number) => {
  const weeksInAMonth = 4;

  return price / (weeksInAMonth * months);
};

const getMonths = (type: DurationType, duration: number) => {
  if (type === 'months') {
    return duration;
  }
  if (type === 'years') {
    return duration * 12;
  }

  return 0;
};

export const normalizeStates = (isoCountry?: string, isoState?: string) => {
  if (isoCountry?.toLowerCase() === 'us') {
    return isoState?.toLowerCase() || null;
  }
  return null;
};

export const getValidatedIsoCountry = (isoCountry: string) =>
  LOCALISED_EU_CONTRIES.includes(isoCountry)
    ? 'EU'
    : LOCALISED_COUNTRIES.includes(isoCountry)
    ? isoCountry.toUpperCase() === 'US'
      ? 'EN_US'
      : isoCountry
    : 'EN';

export const getLocalisedIsoCountry = () => {
  if (typeof window === 'undefined') return 'EN';

  return (
    localStorage.getItem('userLocale') ||
    new URLSearchParams(window.location.search).get('locale') ||
    'EN'
  );
};

// export const getLocalisedIsoCountry = () => {
//   if (typeof window === 'undefined') return 'EN';

//   return new URLSearchParams(window.location.search).get('locale') || 'EN';
// };

export const getLocalisedProduct = (product: Product): Subscription | null => {
  const state = store.getState();
  const localisedName = state?.config?.productLocales?.[product?.key]?.title;
  try {
    const matchingIndex =
      product?.prices.findIndex(
        price =>
          price?.locale?.toLowerCase() ===
          getLocalisedIsoCountry()?.toLowerCase(),
      ) || 0;
    const priceIndex = matchingIndex === -1 ? 0 : matchingIndex;
    const regularPrice = product?.prices?.[priceIndex].price || 0;
    const discount = (product?.prices?.[priceIndex].discount || 0).toFixed(2);
    const finalPrice = product?.prices?.[priceIndex].final_price || 0;
    const savings = (
      (product?.prices?.[priceIndex].discount_percent || 0) * 100
    ).toFixed(0);
    const currencyId = product?.prices?.[priceIndex].currency_id;
    const currency = getCurrency(currencyId);
    const months = getMonths(
      product?.plan?.type || 'months',
      product?.plan?.duration || 0,
    );
    const regularDailyPrice =
      floor(getDailyPrice(months, regularPrice), 2) || 0;
    const discountedDailyPrice =
      floor(getDailyPrice(months, finalPrice), 2) || 0;
    const regularWeeklyPrice =
      floor(getWeeklyPrice(months, regularPrice), 2) || 0;
    const discountedWeeklyPrice =
      floor(getWeeklyPrice(months, finalPrice), 2) || 0;
    const name = product?.name || '';
    const sku = product?.sku || '';

    let discountedTrialPrice: number = 0;
    let discountedDailyTrialPrice: number = 0;
    let trialOldAmount: number = 0;
    let regularTrialDailyPrice: number = 0;
    const duration = product?.plan?.duration;
    const planType = product?.plan?.type;

    const hasTrial = product?.trials.length;
    if (hasTrial) {
      const matchingTrialIndex = product?.trials?.findIndex(
        item =>
          item?.locale?.toLowerCase() ===
          getLocalisedIsoCountry()?.toLowerCase(),
      );

      const trial = product?.trials[matchingTrialIndex];

      discountedTrialPrice = trial.amount || 0;

      const trialDurationQuantity = getTrialDays(
        trial.duration_type || 'days',
        trial.duration || 0,
      );

      discountedDailyTrialPrice =
        floor(
          getTrialDailyPrice(discountedTrialPrice, trialDurationQuantity),
          2,
        ) || 0;

      trialOldAmount = trial.old_amount;

      regularTrialDailyPrice =
        floor(getTrialDailyPrice(trialOldAmount, trialDurationQuantity), 2) ||
        0;
    }

    return {
      regularPrice: regularPrice.toFixed(2),
      discount,
      finalPrice: finalPrice.toFixed(2),
      savings,
      currency,
      currencyId,
      regularDailyPrice,
      discountedDailyPrice,
      regularWeeklyPrice,
      discountedWeeklyPrice,
      months,
      name: localisedName || name,
      sku,
      key: product?.key || '',
      description: product?.description || '',
      discountedTrialPrice: discountedTrialPrice.toFixed(2),
      discountedDailyTrialPrice: discountedDailyTrialPrice.toFixed(2),
      hasTrial,
      trialOldAmount: trialOldAmount.toFixed(2),
      regularTrialDailyPrice: regularTrialDailyPrice.toFixed(2),
      duration,
      planType,
    };
  } catch (e) {
    handleError(e);

    return null;
  }
};
