import React, { FC } from 'react';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { mobile } from 'styles/breakpoints';

import Text from './Text';

import 'swiper/css';
import 'swiper/css/pagination';
import { DynamicImage } from './images/DynamicImage';

interface ReviewsProps {
  title: string;
  reviews: string[];
  starImgUrl?: string;
  starImgUrlAlt: string;
}

const StarImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.75rem;
`;

const StyledDynamicImage = styled(DynamicImage)`
  width: 16.826875rem;
  @media ${mobile} {
    width: 11rem;
  }
`;

const Title = styled(Text).attrs({
  type: 'h1',
})`
  font-size: 2.375rem;
  line-height: 3.325rem;
  text-align: center;

  @media ${mobile} {
    font-size: 1.625rem;
    line-height: 2.275rem;
  }
`;

const SwiperStyled = styled(Swiper)`
  margin-top: 48px;

  .swiper-slide {
    img {
      width: 100%;
      height: auto;
      border-radius: 1rem;
    }
  }

  .swiper-pagination {
    bottom: 0;
    width: 100%;
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: ${({ theme }) => theme.colors.light60};

    &-active {
      background-color: ${({ theme }) => theme.colors.green};
    }
  }

  .swiper-wrapper {
    margin-bottom: 3.375rem;
  }

  @media ${mobile} {
    margin-top: 2rem;

    .swiper-wrapper {
      margin-bottom: 1.875rem;
    }
  }
`;

const Reviews: FC<ReviewsProps> = ({
  title,
  reviews,
  starImgUrl,
  starImgUrlAlt,
}) => (
  <div>
    {!!starImgUrl && (
      <StarImageContainer>
        <StyledDynamicImage src={starImgUrl} alt={starImgUrlAlt} />
      </StarImageContainer>
    )}
    <Title>{title}</Title>

    <SwiperStyled
      modules={[Pagination]}
      pagination={{ clickable: true }}
      allowTouchMove
      effect="slide"
      slidesPerView={1}
      spaceBetween={24}
      breakpoints={{
        480: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
      }}
    >
      {reviews.map((review, index) => (
        <SwiperSlide key={index}>
          <DynamicImage src={review} alt="Slide" />
        </SwiperSlide>
      ))}
    </SwiperStyled>
  </div>
);

export default Reviews;
