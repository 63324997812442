import React, { useEffect, useMemo, useRef, useState } from 'react';
import { KiloCheckout, KiloProduct } from '@core/checkout-web';
import PropTypes from 'prop-types';
import each from 'lodash/each';
import { useSelector } from 'react-redux';
import { handleError as trackErrorSentry } from 'utils/error';
import Tracking from 'utils/tracking';
import { useQuizData } from 'utils/hooks';

import { getLocalisedProduct, normalizeStates } from 'utils/localization';

import OldPayments from './OldPayments';
import OldPaymentsAlt from './OldPaymentsAlt';
import Payments from './Payments';
import { store } from '../../index';

export const PAYMENT_METHOD = {
  stripe: 'kilo-payment-primer_payments',
  paypal: 'kilo-payment-paypal_direct',
};

export const KiloPayments = ({
  code,
  children,
  handleError,
  handleSuccess,
  initialProduct,
}) => {
  const checkoutRef = useRef(new KiloCheckout());
  const IS_STYLE_LOADED = useRef(false);

  const [selectedId, setSelectedId] = useState('');
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const urlConfigs = useSelector(state => state.payment?.gateway);
  const clientEmail = useSelector(state => state.user.user?.email);
  const user = useSelector(state => state.user);

  const data = useQuizData('payments');

  const paymentIds = useMemo(() => {
    const ids = Object.keys(urlConfigs?.prepare_urls ?? []).map(
      url => `kilo-payment-${url}`,
    );
    if (data?.arePaymentsReversed) {
      return [...ids].reverse();
    }
    return ids;
  }, [urlConfigs, data?.arePaymentsReversed]);

  const loadStyle = href =>
    new Promise(resolve => {
      if (IS_STYLE_LOADED?.current || !href) {
        resolve();
        return;
      }

      const element = document.createElement('link');
      const attributes = {
        id: 'kilo-style-' + btoa(href),
        rel: 'stylesheet',
        href,
      };

      each(attributes, (value, name) => (element[name] = value));

      element.onload = () => {
        IS_STYLE_LOADED.current = true;
        resolve();
      };

      document['head'].appendChild(element);
    });

  const load = async () => {
    if (urlConfigs?.style) {
      await loadStyle(urlConfigs.style);
    }

    createUi();
  };

  const onSuccess = data => {
    try {
      const localisedProduct = getLocalisedProduct(
        store.getState().user.selected_plan,
      );

      const productVisiblePrice = localisedProduct?.hasTrial
        ? localisedProduct?.discountedTrialPrice
        : localisedProduct?.finalPrice;

      Tracking.initiatePurchase(
        clientEmail,
        user.code,
        data?.order_id,
        store.getState().user.selected_plan,
        user.geolocation?.iso_country,
        normalizeStates(
          user.geolocation?.iso_country || '',
          user.geolocation?.iso_state || '',
        ),
        user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
        user.user_ip,
      );
      if (typeof handleSuccess === 'function') {
        handleSuccess(data);
      }
    } catch (e) {
      trackErrorSentry(e);
    }

    return false;
  };

  const onSubmit = data => {
    Tracking.trackPaymentSubmit(data.method, data.product);
    if (data?.payment_provider === 'paypal_direct') {
      return;
    }
    setLoading(true);
  };

  const onError = e => {
    if (e.length === 0) {
      return false;
    }
    setLoading(false);
    if (typeof e === 'string') {
      setErrorMessage(e);
    }
    if (typeof e === 'object') {
      setErrorMessage(e.data.message);
    }
    if (typeof handleError === 'function') {
      handleError(e);
    }
    return false;
  };

  const createUi = async () => {
    try {
      setLoading(true);
      const localisedProduct = getLocalisedProduct(initialProduct);

      const productVisiblePrice = localisedProduct.hasTrial
        ? localisedProduct.discountedTrialPrice
        : localisedProduct.finalPrice;

      const product = new KiloProduct(
        initialProduct.key,
        initialProduct.name,
        Number(productVisiblePrice),
      );

      if (urlConfigs.prepare_urls) {
        await Promise.all(
          Object.keys(urlConfigs.prepare_urls).map(async url => {
            await checkoutRef.current.create(urlConfigs.prepare_urls[url], {
              product,
              clientCode: code,
              selector: `#kilo-payment-${url}`,
              callbacks: {
                onError,
                onSubmit,
                onSuccess,
              },
            });
          }),
        );
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code) {
      load();
    }
  }, [code]);
  useEffect(() => {
    const idx = data?.selectedPaymentIndex;
    const initialPaymentMethodIndex =
      initialProduct?.plan?.duration === 12 ? 0 : 1;

    if (idx && idx < paymentIds?.length) {
      return setSelectedId(paymentIds?.[idx] ?? '');
    }
    setSelectedId(paymentIds?.[initialPaymentMethodIndex] ?? '');
  }, [paymentIds, data?.selectedPaymentIndex]);

  useEffect(() => {
    if (initialProduct) {
      const localisedProduct = getLocalisedProduct(initialProduct);
      const productVisiblePrice = localisedProduct.hasTrial
        ? localisedProduct.discountedTrialPrice
        : localisedProduct.finalPrice;
      const order = new KiloProduct(
        initialProduct.key,
        initialProduct.name,
        Number(productVisiblePrice),
        Number(productVisiblePrice) * 100,
      );

      checkoutRef.current.selectProduct(order, true);
    }
  }, [initialProduct]);

  return (
    <>
      <div hidden={loading}>
        {data?.oldPaymentsAlt ? (
          <OldPaymentsAlt
            ids={paymentIds}
            selectedId={selectedId}
            onItemClick={setSelectedId}
            unitePayments={data?.unitePayments}
            errorMessage={errorMessage}
          />
        ) : (
          <OldPayments
            ids={paymentIds}
            selectedId={selectedId}
            unitePayments={data?.unitePayments}
            onItemClick={setSelectedId}
            errorMessage={errorMessage}
          />
        )}
      </div>
      {loading ? children : null}
    </>
  );
};

KiloPayments.propTypes = {
  children: PropTypes.any,
  code: PropTypes.string,
  handleError: PropTypes.func,
  handleSuccess: PropTypes.func,
  initialProduct: PropTypes.object,
};
