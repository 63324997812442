import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  name?: string;
  title?: string;
  description?: string;
}

export const Seo: React.FC<Props> = ({
  name = 'Effecto: Personalized ADHD Management Plan',
  title = 'ADHD Management Plan | Effecto',
  description = 'Effecto builds you a personalized ADHD management plan to improve focus, manage disorganization and create your ultimate routine.',
  children,
}) => (
  <Helmet>
    {title ? <title>{title}</title> : null}
    <meta itemProp="name" content={name} />
    <meta id="description" name="description" content={description} />
    <link rel="canonical" href={location.toString()} />

    {/* FB tags */}
    <meta property="og:url" content="https://effecto.app" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={name} />
    <meta property="og:description" content={description} />
    {children}
  </Helmet>
);
