import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Question, QuizAnswer } from 'types/quiz';
import { DynamicImage, Text } from 'components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import Disclaimer from './Disclaimer';
import CircleTick from 'assets/icons/payments/circle-tick.svg';
import theme from 'utils/theme';
import LandingLayout from './LandingLayout';
import LCFooter from 'components/LCFooter';
import { useScrollToTop } from 'utils/hooks';
import Header from 'components/reviews/Header';
import PRFooter from 'components/PRFooter';
import { stubTrue } from 'lodash';
import { useLocation } from '@reach/router';
import LoopingBanner from 'components/LoopingBanner';
import WhitePercent from 'assets/icons/white-percent.svg';
import Triangle from 'assets/icons/triangle.svg';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
}

interface ButtonsContainerProps {
  length: number;
  hasBanner?: boolean;
}

const TitleContainer = styled.div`
  padding-bottom: 1.5rem;

  @media ${tablet} {
    text-align: center;
    padding-bottom: 1rem;
    margin-top: 1.5rem;
  }
`;

const Title = styled(Text)`
  @media ${tablet} {
  }

  * {
    font-size: inherit;
    font-family: inherit;
  }
`;

const StyledHeroImage = styled(DynamicImage)`
  width: 14.18288rem;
  height: 12.5rem;
`;

const HeroImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DescriptionContainer = styled.div`
  padding-bottom: 4rem;

  @media ${tablet} {
    text-align: center;
    text-align-last: center;
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: center;
  }
`;

const CheckmarkListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  @media ${tablet} {
    align-items: center;
    margin-bottom: 1.5rem;
  }
`;

const CheckmarkItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 0.3125rem;
`;

const CheckTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.dark80};
  margin-left: 0.3125rem;
`;

const ButtonsTitleContainer = styled.div`
  padding-bottom: 2rem;

  @media ${tablet} {
    text-align: center;
    padding-bottom: 0.75rem;
    display: flex;
    justify-content: center;
  }
`;

const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  flex-wrap: wrap;
  @media ${tablet} {
    justify-content: center;
  }
`;

const ButtonsContainerWithBanner = styled.div<ButtonsContainerProps>`
  display: flex;
  gap: 1rem;
  @media ${tablet} {
    ${({ hasBanner }) => !hasBanner && 'padding-bottom: 32px;'}
    justify-content: center;
  }
`;

const GenderButtonWithBanner = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})<{ marginRight?: boolean; isSingleButton?: boolean }>`
  padding: 0 1rem;
  width: 100%;
  height: 3.5rem;

  @media ${tablet} {
    height: 3rem;
  }
`;

const GenderButton = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})<{ marginRight?: boolean; isSingleButton?: boolean }>`
  padding: 0 1rem;
  width: 100%;
  height: 3.5rem;
  max-width: 15rem;
  margin-right: 1rem;
  flex-basis: ${({ isSingleButton }) =>
    isSingleButton ? '100%' : 'calc((100% / 3) - 1rem)'};
  @media ${tablet} {
    height: 3rem;
    flex-basis: ${({ isSingleButton }) =>
      isSingleButton ? '100%' : 'calc(50% - 1rem)'};
    margin-right: ${({ marginRight }) => (marginRight ? 1 : 0)}rem;
  }
`;

const Label = styled(Text)<{ descriptionIsMaxWidth?: boolean }>`
  font-weight: normal;

  * {
    font-size: inherit;
    font-family: inherit;
  }

  @media ${mobile} {
    width: ${({ descriptionIsMaxWidth }) =>
      descriptionIsMaxWidth ? '100%' : '19.875rem'};
  }
`;

const OtherGenderButton = styled(GenderButton)`
  flex-basis: calc(33% - 1rem);
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color] : 'transparent'};

  @media ${tablet} {
    flex-basis: calc(50% - 1rem);
    margin-top: 0.5rem;
  }
`;

const MdpiContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Mdpi = styled.div`
  width: 19rem;
  margin-top: 2rem;
  align-self: center;
  @media ${tablet} {
    margin-top: 1.5rem;
  }
`;

const LeftContainer = styled.div`
  flex: 55;
  max-width: 32.625rem;
  width: 100%;
  margin-right: 1rem;
  @media ${tablet} {
    margin-right: 0;
  }
`;
const RightContainer = styled.div`
  flex: 45;
  max-width: 32.625rem;
  width: 100%;
`;

const ButtonsMainConatiner = styled.div`
  max-width: 38.8125rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${tablet} {
    max-width: unset;
  }
`;

const ButtonsBanner = styled.p`
  position: relative;
  display: flex;
  max-width: 38.8125rem;
  width: 100%;
  padding: 0.375rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  background: #a4202f;
  color: #fef3f3;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin-top: 0.875rem;
  gap: 0.5rem;
  height: 3.5rem;
  @media ${tablet} {
    max-width: unset;
    height: 3rem;
  }
`;

const ButtonBannerText = styled.p`
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.03rem;

  b {
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
  }

  span {
    font-weight: 700;
    background: radial-gradient(
      71.77% 45.83% at 96.09% 40.38%,
      #edce82 0%,
      #c7a753 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    font-family: 'Open Sans', sans-serif;
  }

  @media ${tablet} {
    font-size: 0.8125rem;
    letter-spacing: -0.024rem;
  }
`;

const LeftTriangle = styled(Triangle)`
  position: absolute;
  top: -0.375rem;
  left: 20%;
`;

const RightTriangle = styled(Triangle)`
  position: absolute;
  top: -0.375rem;
  right: 20%;
`;

const Landing: FC<LandingProps> = ({ question, onOptionSelect }) => {
  const { isMobile } = useQuery();
  const location = useLocation();
  const quiz = new URLSearchParams(location.search).get('lp') ?? 'main';
  const isAddsFlow = location.pathname.includes('start') || quiz !== 'main';

  const renderGenderButtons = (options: any[]) => (
    <>
      {options?.map((option, index) => (
        <GenderButton
          key={index}
          color={option?.color}
          colorActive={option?.color}
          onClick={() =>
            onOptionSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          marginRight={index === 0}
          isSingleButton={question?.options?.length === 1}
        >
          {option?.label}
        </GenderButton>
      ))}
    </>
  );

  const renderGenderButtonsWithBanner = (options: any[]) => (
    <>
      {options?.map((option, index) => (
        <GenderButtonWithBanner
          key={index}
          color={option?.color}
          fontColor={option?.textColor}
          colorActive={option?.color}
          onClick={() =>
            onOptionSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          marginRight={true}
          isSingleButton={question?.options?.length === 1}
        >
          {option?.label}
        </GenderButtonWithBanner>
      ))}
    </>
  );

  const renderGenderBasic = (options: any[]) => {
    if (!options.length) {
      return null;
    }
    return options?.map((option, index) => {
      const hasColor = !!option.color;
      return (
        <OtherGenderButton
          onClick={() =>
            onOptionSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          color={option?.color}
          colorActive={option?.color}
          key={index}
        >
          {hasColor ? (
            option?.label
          ) : (
            <Text type="body500">{option?.label}</Text>
          )}
        </OtherGenderButton>
      );
    });
  };

  const renderGenderOptions = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainer
        length={[...primaryOptions, ...secondaryOptions].length}
      >
        {renderGenderButtons(primaryOptions)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainer>
    );
  };

  const renderGenderOptionsWithBanner = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainerWithBanner
        hasBanner={!!question?.bannerTexts}
        length={[...primaryOptions, ...secondaryOptions].length}
      >
        {renderGenderButtonsWithBanner(primaryOptions)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainerWithBanner>
    );
  };

  const renderCheckmarks = (title: string, index: number) => (
    <CheckmarkItem key={index}>
      <CircleTick
        fill={theme.colors.green}
        width={isMobile ? 16 : 24}
        height={isMobile ? 16 : 24}
      />
      <CheckTitle type={isMobile ? 'body' : 'h3400'}>{title}</CheckTitle>
    </CheckmarkItem>
  );

  const getTitleFont = () => {
    if (isMobile) {
      return question?.titleFontMobile ? question.titleFontMobile : 'h1';
    }
    return question?.titleFont ? question.titleFont : 'h13Rem';
  };

  useEffect(() => {
    setTimeout(() => {
      window.scroll({ top: -1, left: 0, behavior: 'smooth' });
    }, 10);
  }, [location.href, location.pathname]);

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <>
      {question?.bannerTexts ? (
        <Header
          isMobileApp={isAddsFlow ? true : false}
          bgHex="#e8f6f2"
          isNotSticky={true}
        />
      ) : (
        <Header
          isMobileApp={isAddsFlow ? true : false}
          bgHex="#e8f6f2"
          isNotSticky={false}
        />
      )}

      {question?.bannerTexts && (
        <LoopingBanner
          bannerTexts={question?.bannerTexts.texts}
          primaryTextColor={question?.bannerTexts.primaryColor}
          secondaryTextColor={question?.bannerTexts.secondaryColor}
          primaryGradient={question?.bannerTexts.primaryGradient}
          backgroundColor={question?.bannerTexts.backgroundColor}
          isNotSticky={false}
        />
      )}

      <Container>
        <Inner isFullHeight={isAddsFlow}>
          <InnerContainer>
            <LeftContainer>
              {isMobile && question.mobileImg ? (
                <HeroImageWrapper>
                  <StyledHeroImage
                    src={question.mobileImg}
                    alt={question.alt}
                  />
                </HeroImageWrapper>
              ) : null}
              <TitleContainer>
                <Title
                  type={isMobile ? 'countS' : 'h13Rem'}
                  dangerouslySetInnerHTML={{ __html: question?.title || '' }}
                />
              </TitleContainer>
              {!!question?.description && (
                <DescriptionContainer>
                  <Label
                    type={isMobile ? 'body' : 'h3400'}
                    color="dark80"
                    dangerouslySetInnerHTML={{
                      __html: question?.description || '',
                    }}
                    descriptionIsMaxWidth={question?.descriptionIsMaxWidth}
                  />
                </DescriptionContainer>
              )}
              {!!question?.checkmarkList && (
                <CheckmarkListContainer>
                  {question.checkmarkList.map(renderCheckmarks)}
                </CheckmarkListContainer>
              )}
              <ButtonsTitleContainer>
                <Label
                  dangerouslySetInnerHTML={{ __html: question?.label }}
                  type={isMobile ? 'bodyS500' : 'body500'}
                />
              </ButtonsTitleContainer>
              {question?.bannerButtonText ? (
                <ButtonsMainConatiner>
                  {renderGenderOptionsWithBanner()}
                  {question?.bannerButtonText && (
                    <ButtonsBanner>
                      <WhitePercent />
                      <LeftTriangle />
                      <RightTriangle />
                      <ButtonBannerText
                        dangerouslySetInnerHTML={{
                          __html: question?.bannerButtonText,
                        }}
                      />
                    </ButtonsBanner>
                  )}
                </ButtonsMainConatiner>
              ) : (
                renderGenderOptions()
              )}
              {isMobile && !!question?.mdpiImage?.desktopImg && (
                <MdpiContainer>
                  <Mdpi>
                    <DynamicImage
                      src={question.mdpiImage.desktopImg}
                      alt={question.mdpiImage.alt}
                    />
                  </Mdpi>
                </MdpiContainer>
              )}
            </LeftContainer>
            <RightContainer>
              <>
                {question.desktopImg && !isMobile ? (
                  <DynamicImageStyled
                    src={question.desktopImg}
                    alt={question.alt}
                  />
                ) : null}
                {!!question?.mdpiImage?.desktopImg && (
                  <MdpiContainer>
                    <Mdpi>
                      <DynamicImage
                        src={question.mdpiImage.desktopImg}
                        alt={question.mdpiImage.alt}
                      />
                    </Mdpi>
                  </MdpiContainer>
                )}
              </>
            </RightContainer>
          </InnerContainer>
        </Inner>
        {!isAddsFlow ? (
          <LCFooter disclaimerText={question?.disclaimerParagraphs || []} />
        ) : (
          <PRFooter disclaimerText={question?.disclaimerParagraphsPR || []} />
        )}
      </Container>
    </>
  );
};

export default Landing;

const Container = styled.div<{ height?: string }>`
  background: #e8f6f2;
  @media ${mobile} {
  }
`;

const Inner = styled.div<{ isFullHeight?: boolean }>`
  width: 100%;
  max-width: 77.25rem;
  margin: 0 auto;

  ${({ isFullHeight }) =>
    isFullHeight
      ? 'min-height: calc(100vh - 72px);'
      : 'min-height: calc(100vh - 225px);'}
  padding: 3rem 3rem;
  @media ${tablet} {
    padding: 2.375rem 1rem 3.5rem;
    ${({ isFullHeight }) =>
      isFullHeight
        ? 'min-height: calc(100vh - 64px);'
        : 'min-height: calc(100vh - 225px);'}
  }
`;

const InnerContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${tablet} {
    flex-direction: column-reverse;
  }
`;

const DynamicImageStyled = styled(DynamicImage)`
  width: 100%;
`;

const AddsTnc = styled.p`
  color: #555770;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.021rem;
  max-width: 25rem;
  width: 100%;
  padding-top: 1.5rem;
  @media ${tablet} {
    text-align: center;
  }
`;

const LinkStyled = styled(AddsTnc)`
  font-weight: 600;
  display: inline;
  cursor: pointer;
`;
