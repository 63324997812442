import React, { FC } from 'react';

const LogoWithName: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="99"
    height="40"
    viewBox="0 0 99 40"
    fill="none"
  >
    <g clipPath="url(#clip0_815_406)">
      <path
        d="M26.7509 13.3333L33.4371 13.3333C37.1369 13.3333 40.1411 16.3275 40.1411 19.997C40.1411 23.6843 37.1369 26.6608 33.4371 26.6608L26.7509 26.6608L26.7509 33.3422C26.7509 37.0118 23.7644 40.0059 20.0646 40.0059C16.3649 40.0059 13.3784 37.0118 13.3784 33.3422L13.3784 26.6608C13.3784 19.3378 19.4031 13.3333 26.7509 13.3333Z"
        fill="#FF5C5C"
      />
      <path
        d="M26.7627 13.3274C26.7627 20.688 20.7756 26.6549 13.3902 26.6549L6.68624 26.6549C3.00428 26.6564 1.0399e-09 23.6784 8.78031e-10 19.9928C7.16801e-10 16.3215 3.00428 13.329 6.68624 13.329L13.3902 13.329L13.3902 6.66372C13.3902 2.97648 16.3767 -7.19223e-10 20.0765 -8.81708e-10C23.7762 -1.04419e-09 26.7627 2.97648 26.7627 6.66372L26.7627 13.3274Z"
        fill="#FDAC42"
      />
      <path
        d="M51.7969 26.6666C50.8635 26.6666 50.0597 26.4684 49.3853 26.072C48.715 25.6715 48.1968 25.1095 47.8309 24.3862C47.469 23.6588 47.2881 22.8066 47.2881 21.8299C47.2881 20.8654 47.469 20.0153 47.8309 19.2796C48.1968 18.5441 48.7068 17.9699 49.3605 17.5571C50.0185 17.1443 50.7874 16.9379 51.6674 16.9379C52.202 16.9379 52.7201 17.0258 53.2218 17.2015C53.7234 17.3772 54.1737 17.6531 54.5725 18.0291C54.9714 18.4051 55.2859 18.8934 55.5163 19.4942C55.7465 20.0909 55.8617 20.8163 55.8617 21.6704L55.8617 22.3203L48.3305 22.3203L48.3305 20.9471L54.0544 20.9471C54.0544 20.4649 53.9558 20.0378 53.7583 19.6659C53.561 19.2899 53.2834 18.9936 52.9256 18.777C52.572 18.5604 52.1567 18.4521 51.6798 18.4521C51.1616 18.4521 50.7093 18.5788 50.3228 18.8322C49.9404 19.0814 49.6442 19.4084 49.4345 19.813C49.229 20.2135 49.1262 20.6488 49.1262 21.1188L49.1262 22.1915C49.1262 22.821 49.2372 23.3563 49.4593 23.7976C49.6854 24.2391 50 24.5762 50.4029 24.8092C50.8059 25.0381 51.2768 25.1524 51.8155 25.1524C52.165 25.1524 52.4836 25.1034 52.7715 25.0053C53.0593 24.9032 53.3082 24.752 53.5179 24.5518C53.7276 24.3514 53.8879 24.1042 53.9989 23.81L55.7445 24.1225C55.6047 24.6334 55.3538 25.081 54.9919 25.4651C54.6342 25.8452 54.184 26.1415 53.6412 26.354C53.1025 26.5624 52.4877 26.6666 51.7969 26.6666Z"
        fill="#1C1C28"
      />
      <path
        d="M61.3743 17.0605L61.3743 18.5318L56.0205 18.5318L56.0205 17.0605L61.3743 17.0605ZM57.4884 26.4766L57.4884 15.9693C57.4884 15.3809 57.618 14.8924 57.8771 14.5042C58.1362 14.1119 58.4795 13.8196 58.9071 13.6275C59.3348 13.4314 59.7995 13.3333 60.3011 13.3333C60.6712 13.3333 60.9878 13.364 61.251 13.4253C61.5141 13.4825 61.7094 13.5356 61.837 13.5846L61.4052 15.0682C61.3188 15.0436 61.2079 15.0151 61.0721 14.9823C60.9364 14.9455 60.772 14.9272 60.5786 14.9272C60.1305 14.9272 59.8097 15.0375 59.6165 15.2582C59.4273 15.4789 59.3328 15.7976 59.3328 16.2145L59.3328 26.4766L57.4884 26.4766Z"
        fill="#1C1C28"
      />
      <path
        d="M66.7439 17.0605L66.7439 18.5318L61.3901 18.5318L61.3901 17.0605L66.7439 17.0605ZM62.8581 26.4766L62.8581 15.9693C62.8581 15.3809 62.9876 14.8924 63.2467 14.5042C63.5057 14.1119 63.8491 13.8196 64.2767 13.6275C64.7044 13.4314 65.1691 13.3333 65.6707 13.3333C66.0408 13.3333 66.3574 13.364 66.6206 13.4253C66.8837 13.4825 67.0791 13.5356 67.2065 13.5846L66.7748 15.0682C66.6884 15.0436 66.5774 15.0151 66.4417 14.9823C66.306 14.9455 66.1416 14.9272 65.9483 14.9272C65.5001 14.9272 65.1793 15.0375 64.986 15.2582C64.7969 15.4789 64.7023 15.7976 64.7023 16.2145L64.7023 26.4766L62.8581 26.4766Z"
        fill="#1C1C28"
      />
      <path
        d="M71.3149 26.6666C70.3815 26.6666 69.5776 26.4684 68.9032 26.072C68.233 25.6715 67.7149 25.1095 67.3489 24.3862C66.987 23.6588 66.8062 22.8066 66.8062 21.8299C66.8062 20.8654 66.987 20.0153 67.3489 19.2796C67.7149 18.5441 68.2247 17.9699 68.8786 17.5571C69.5365 17.1443 70.3054 16.9379 71.1853 16.9379C71.7199 16.9379 72.2381 17.0258 72.7397 17.2015C73.2414 17.3772 73.6917 17.6531 74.0906 18.0291C74.4894 18.4051 74.804 18.8934 75.0342 19.4942C75.2645 20.0909 75.3797 20.8163 75.3797 21.6704L75.3797 22.3203L67.8486 22.3203L67.8486 20.9471L73.5724 20.9471C73.5724 20.4649 73.4737 20.0378 73.2764 19.6659C73.0789 19.2899 72.8014 18.9936 72.4437 18.777C72.0901 18.5604 71.6748 18.4521 71.1977 18.4521C70.6796 18.4521 70.2273 18.5788 69.8408 18.8322C69.4583 19.0814 69.1623 19.4084 68.9526 19.813C68.747 20.2135 68.6441 20.6488 68.6441 21.1188L68.6441 22.1915C68.6441 22.821 68.7552 23.3563 68.9772 23.7976C69.2034 24.2391 69.518 24.5762 69.921 24.8092C70.3239 25.0381 70.7948 25.1524 71.3334 25.1524C71.6829 25.1524 72.0016 25.1034 72.2895 25.0053C72.5773 24.9032 72.8261 24.752 73.0358 24.5518C73.2455 24.3514 73.4059 24.1042 73.5169 23.81L75.2624 24.1225C75.1226 24.6334 74.8718 25.081 74.51 25.4651C74.1522 25.8452 73.7019 26.1415 73.1592 26.354C72.6205 26.5624 72.0058 26.6666 71.3149 26.6666Z"
        fill="#1C1C28"
      />
      <path
        d="M80.3742 26.6666C79.4572 26.6666 78.6678 26.4603 78.0057 26.0475C77.3478 25.6306 76.842 25.0564 76.4884 24.3249C76.1348 23.5933 75.958 22.7555 75.958 21.8114C75.958 20.8552 76.1388 20.0112 76.5007 19.2796C76.8626 18.5441 77.3724 17.9699 78.0304 17.5571C78.6883 17.1443 79.4635 16.9379 80.3557 16.9379C81.0754 16.9379 81.7168 17.0708 82.2802 17.3364C82.8435 17.598 83.2978 17.9658 83.6433 18.4399C83.9928 18.9139 84.2004 19.4676 84.2663 20.1011L82.4714 20.1011C82.3727 19.6598 82.1465 19.2796 81.7929 18.9609C81.4434 18.6421 80.9746 18.4828 80.3866 18.4828C79.8726 18.4828 79.4223 18.6176 79.0358 18.8873C78.6534 19.153 78.3553 19.5331 78.1415 20.0275C77.9276 20.518 77.8207 21.0983 77.8207 21.7685C77.8207 22.4552 77.9255 23.0478 78.1352 23.5463C78.3449 24.045 78.641 24.4311 79.0234 24.705C79.41 24.9788 79.8644 25.1156 80.3866 25.1156C80.7361 25.1156 81.0527 25.0523 81.3365 24.9256C81.6243 24.7949 81.8649 24.6089 82.0581 24.3678C82.2556 24.1266 82.3932 23.8365 82.4714 23.4973L84.2663 23.4973C84.2004 24.1062 84.0011 24.6498 83.668 25.128C83.3349 25.6061 82.8888 25.9821 82.3295 26.2559C81.7744 26.5298 81.1226 26.6666 80.3742 26.6666Z"
        fill="#1C1C28"
      />
      <path
        d="M89.6188 17.0606L89.6188 18.5318L84.4438 18.5318L84.4438 17.0606L89.6188 17.0606ZM85.8317 14.8046L87.676 14.8046L87.676 23.7119C87.676 24.0675 87.7294 24.3351 87.8363 24.5149C87.9432 24.6907 88.081 24.8112 88.2496 24.8767C88.4223 24.9379 88.6093 24.9686 88.8109 24.9686C88.9588 24.9686 89.0884 24.9583 89.1994 24.9379C89.3105 24.9175 89.3969 24.9011 89.4585 24.8889L89.7915 26.4031C89.6846 26.4439 89.5325 26.4848 89.3351 26.5257C89.1378 26.5707 88.891 26.5951 88.5949 26.5992C88.1097 26.6075 87.6575 26.5216 87.238 26.3418C86.8186 26.1619 86.4794 25.884 86.2203 25.508C85.9612 25.1321 85.8317 24.66 85.8317 24.092L85.8317 14.8046Z"
        fill="#1C1C28"
      />
      <path
        d="M94.5057 26.6666C93.6175 26.6666 92.8423 26.4643 92.1803 26.0598C91.5183 25.6552 91.0043 25.0891 90.6383 24.3616C90.2723 23.6342 90.0894 22.7841 90.0894 21.8114C90.0894 20.8348 90.2723 19.9805 90.6383 19.249C91.0043 18.5174 91.5183 17.9494 92.1803 17.5448C92.8423 17.1402 93.6175 16.9379 94.5057 16.9379C95.3939 16.9379 96.1689 17.1402 96.8309 17.5448C97.4931 17.9494 98.007 18.5174 98.373 19.249C98.7389 19.9805 98.922 20.8348 98.922 21.8114C98.922 22.7841 98.7389 23.6342 98.373 24.3616C98.007 25.0891 97.4931 25.6552 96.8309 26.0598C96.1689 26.4643 95.3939 26.6666 94.5057 26.6666ZM94.5118 25.128C95.0875 25.128 95.5645 24.9768 95.9428 24.6743C96.3211 24.3719 96.6007 23.9693 96.7817 23.4666C96.9667 22.964 97.0592 22.4102 97.0592 21.8053C97.0592 21.2045 96.9667 20.6529 96.7817 20.1502C96.6007 19.6434 96.3211 19.2368 95.9428 18.9302C95.5645 18.6238 95.0875 18.4704 94.5118 18.4704C93.9321 18.4704 93.4509 18.6238 93.0685 18.9302C92.6902 19.2368 92.4085 19.6434 92.2234 20.1502C92.0426 20.6529 91.9521 21.2045 91.9521 21.8053C91.9521 22.4102 92.0426 22.964 92.2234 23.4666C92.4085 23.9693 92.6902 24.3719 93.0685 24.6743C93.4509 24.9768 93.9321 25.128 94.5118 25.128Z"
        fill="#1C1C28"
      />
    </g>
    <defs>
      <clipPath id="clip0_815_406">
        <rect width="99" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoWithName;
