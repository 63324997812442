import React, { FC } from 'react';
import styled from 'styled-components';

import { Question, QuestionOption, QuizAnswer } from 'types/quiz';
import LandingLayout from './LandingLayout';
import { Text } from 'components';
import { mobile, useQuery } from 'styles/breakpoints';
import Disclaimer from './Disclaimer';

interface Landing2Props {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
}

const LandingLayoutStyled = styled(LandingLayout)`
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Wrapper = styled.div`
  max-width: 37.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Text).attrs({
  color: 'dark80',
})`
  margin-bottom: 1rem;
  text-align: center;
  @media ${mobile} {
    margin-bottom: 1.5rem;
  }
`;

const Description = styled(Text).attrs({
  color: 'dark80',
})`
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.375rem;
  max-width: 32.625rem;
  @media ${mobile} {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
`;

const Label = styled(Text).attrs({
  color: 'dark80',
})`
  text-align: center;
`;

const OptionContainer = styled.div`
  margin-top: 1rem;
  padding: 0.75rem;
  width: 21.4375rem;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.light0};
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: 0.09rem solid ${({ theme }) => theme.colors.light100};
  :active {
    border: 0.09rem solid ${({ theme }) => theme.colors.green};
    > p {
      color: ${({ theme }) => theme.colors.green} !important;
    }
  }
`;

const OptionAnswer = styled(Text).attrs({
  type: 'body',
})``;

const DisclaimerStyled = styled(Disclaimer)`
  width: 100%;
`;

const Landing2: FC<Landing2Props> = ({ question, onOptionSelect }) => {
  const { isMobile } = useQuery();
  if (!question) {
    return null;
  }
  const { title, description, label, options, disclaimerParagraphs } = question;

  const renderOptions = (option: QuestionOption, index: number) => (
    <OptionContainer
      key={index}
      onClick={() =>
        onOptionSelect({
          [question?.key]: option?.value,
          label: option?.label,
        })
      }
    >
      <OptionAnswer>{option.label}</OptionAnswer>
    </OptionContainer>
  );

  return (
    <LandingLayoutStyled question={question}>
      <Container>
        <Wrapper>
          <Title type={isMobile ? 'countS' : 'h13Rem'}>{title}</Title>
          <Description type={isMobile ? 'body' : 'h2400'}>
            {description}
          </Description>
          <Label type={isMobile ? 'bodyS' : 'body'}>{label}</Label>
          {options?.map(renderOptions)}
        </Wrapper>
        {!isMobile && (
          <DisclaimerStyled paragraphs={question?.disclaimerParagraphs ?? []} />
        )}
      </Container>
    </LandingLayoutStyled>
  );
};

export default Landing2;
